import React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import ScrollUp from "../components/ScrollUp"
import ServicesItem from "../components/ServicesItem"

import { StaticQuery, graphql } from "gatsby"
export default function Palvelut() {
  return (
    <StaticQuery
      query={graphql`
        query ServiceQuery {
          allSanityServicePage {
            nodes {
              Language
              maintitle
            }
          }
          allSanityServices {
            nodes {
              _id
              title
              slug {
                current
              }
              service_desc {
                children {
                  text
                }
              }
              mainImage {
                asset {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const resource = data.allSanityServicePage.nodes[0]
        const resource2 = data.allSanityServices.nodes
        console.log(resource2)
        return (
          <div className="wrapper">
            <Header link="/palvelut" />
            <section className="services-layout1 pb-90 bg-gray">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                    <div className="heading text-center mb-40">
                      <h2 className="heading__title">{resource.maintitle}</h2>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {resource2.map(item => {
                    return (
                      <ServicesItem
                        key={item._id}
                        title={item.title}
                        desc={item.service_desc[0].children[0].text}
                        link={`/palvelut/${item.slug.current}`}
                        images={item.mainImage.asset.fluid.src}
                      />
                    )
                  })}
                </div>
              </div>
            </section>
            <Footer />
            <ScrollUp />
          </div>
        )
      }}
    />
  )
}
