import React, { useState } from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { StaticQuery, graphql } from "gatsby"
import Language from "../Language"

export default function Header({ link }) {
  const [activeIcon, setActiveIcon] = useState(false)
  const intl = useIntl()
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          allSanityHeader {
            nodes {
              Language
              phone
              time
              email
              button
              id
              mainImage {
                caption
                alt

                asset {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          allSanityServices {
            nodes {
              _id
              title
              title_en
              slug {
                current
              }
            }
          }
        }
      `}
      render={data => {
        let resource = null
        if (intl.locale === "en") {
          resource = data.allSanityHeader.nodes.filter(
            item => item.Language === "en"
          )
          resource = resource[0]
          console.log(resource)
        } else {
          resource = data.allSanityHeader.nodes.filter(
            item => item.Language === "fi"
          )
          resource = resource[0]
          console.log(resource)
        }

        const resource2 = data.allSanityServices.nodes
        console.log(resource2)
        return (
          <>
            {resource && (
              <header className="header header-layout-main">
                <nav className="navbar navbar-expand-lg">
                  <div className="container">
                    <div className="mobile-lang header-actions d-none d-lg-block">
                      <Language />
                    </div>
                    <a href="/" className="navbar-brand">
                      <img
                        src={resource.mainImage.asset.fluid.src}
                        className="logo-dark"
                        alt={resource.mainImage.alt}
                      />
                    </a>
                    <div className="header-topbar d-none d-lg-block">
                      <div className="d-flex flex-wrap">
                        <ul className="header-topbar__contact d-flex flex-wrap list-unstyled mb-0">
                          <li>
                            <i className="fas fa-phone-square" />
                            <div>
                              <span>
                                <FormattedMessage id="phone" />
                              </span>
                              <strong>
                                <a href={"tel:" + resource.phone}>
                                  {resource.phone}
                                </a>
                              </strong>
                            </div>
                          </li>
                          <li>
                            <i className="fas fa-envelope-open" />
                            <div>
                              <span>
                                <FormattedMessage id="email" />
                              </span>
                              <strong>
                                <a href={"mailto:" + resource.email}>
                                  {resource.email}
                                </a>
                              </strong>
                            </div>
                          </li>
                          <li>
                            <i className="fas fa-user-clock" />
                            <div>
                              <span>
                                <FormattedMessage id="opentime" />
                              </span>
                              <strong>
                                <a href="#">{resource.time}</a>
                              </strong>
                            </div>
                          </li>
                        </ul>
                        <a
                          href="/yhteystiedot"
                          className="btn btn__primary header__btn"
                        >
                          <span>{resource.button}</span>
                        </a>
                      </div>
                    </div>

                    <svg
                      className={
                        activeIcon
                          ? "ham hamRotate ham8 navbar-toggler active"
                          : "ham hamRotate ham8 navbar-toggler"
                      }
                      viewBox="0 0 100 100"
                      onClick={() => setActiveIcon(!activeIcon)}
                    >
                      <path
                        className="line top"
                        d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                      />
                      <path className="line middle" d="m 30,50 h 40" />
                      <path
                        className="line bottom"
                        d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                      />
                    </svg>
                  </div>

                  <div className="navbar__bottom sticky-navbar">
                    <div className="container">
                      <div
                        className={
                          activeIcon
                            ? "collapse navbar-collapse menu-opened"
                            : "collapse navbar-collapse"
                        }
                      >
                        <ul className="navbar-nav">
                          <li className="nav__item">
                            <a
                              href="/"
                              className={
                                link === "/"
                                  ? "nav__item-link active"
                                  : "nav__item-link"
                              }
                            >
                              <FormattedMessage id="home" />
                            </a>
                          </li>

                          <li className="nav__item with-dropdown">
                            <a
                              href="#"
                              className={
                                link === "/palvelut"
                                  ? "dropdown-toggle nav__item-link active"
                                  : "dropdown-toggle nav__item-link"
                              }
                            >
                              <FormattedMessage id="services" />
                            </a>
                            <i
                              data-toggle="dropdown"
                              className="fa fa-angle-down d-block d-lg-none"
                            />
                            <ul className="dropdown-menu">
                              {resource2.map(url => {
                                return (
                                  <li key={url._id} className="nav__item">
                                    <a
                                      href={`/palvelut/${url.slug.current}`}
                                      className={
                                        typeof window !== "undefined" &&
                                        window.location.href.includes(
                                          url.slug.current
                                        )
                                          ? "nav__item-link active"
                                          : "nav__item-link"
                                      }
                                    >
                                      {intl.locale === "en"
                                        ? url.title_en
                                        : url.title}
                                    </a>
                                  </li>
                                )
                              })}
                            </ul>
                          </li>

                          {/* <li className="nav__item">
                            <a
                              href="/hinnat"
                              className={
                                link === "/hinnat"
                                  ? "nav__item-link active"
                                  : "nav__item-link"
                              }
                            >
                              <FormattedMessage id="price" />
                            </a>
                          </li> */}

                          <li className="nav__item">
                            <a
                              href="/yhteystiedot"
                              className={
                                link === "/yhteystiedot"
                                  ? "nav__item-link active"
                                  : "nav__item-link"
                              }
                            >
                              <FormattedMessage id="contact" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="header-actions d-none d-lg-block">
                        <Language />
                      </div>
                    </div>
                  </div>
                </nav>
              </header>
            )}
          </>
        )
      }}
    />
  )
}
