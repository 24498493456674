import React from "react"

export default function ServicesItem({ title, desc, link, images }) {
  return (
    <div className="col-sm-12 col-md-4 col-lg-4">
      <div className="service-item">
        <div className="service-item__content">
          <h4 className="service-item__title">{title}</h4>
          <p className="service-item__desc">{desc}</p>
          <a href={link} className="btn btn__primary btn__link">
            <i className="fas fa-arrow-circle-right" />
            <span>Lue lisää</span>
          </a>
        </div>

        <div className="service-item__img">
          <img src={images} alt="service" className="w-100" />
        </div>
      </div>
    </div>
  )
}
