import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { StaticQuery, graphql } from "gatsby"

export default function Footer() {
  const intl = useIntl()
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          allSanityFooter {
            nodes {
              Language
              address
              addressMap
              companyDesc {
                children {
                  text
                }
              }
              contacttext
              email
              phone
              mainImage {
                alt
                caption
                asset {
                  fluid {
                    src
                  }
                }
              }
              copyrightText {
                children {
                  text
                }
              }
              app {
                icon
                link
              }
            }
          }
          allSanityHeader {
            nodes {
              time
            }
          }
        }
      `}
      render={data => {
        let resource = null
        if (intl.locale === "en") {
          resource = data.allSanityFooter.nodes.filter(
            item => item.Language === "en"
          )
          resource = resource[0]
          console.log(resource)
        } else {
          resource = data.allSanityFooter.nodes.filter(
            item => item.Language === "fi"
          )
          resource = resource[0]
          console.log(resource)
        }

        return (
          <>
            {resource && (
              <footer className="footer">
                <div className="footer-contact">
                  <div className="container">
                    <div className="row justify-content-between">
                      <div className="footer-contact__item d-flex align-items-center">
                        <div className="footer-contact__icon">
                          <i className="fas fa-phone-square" />
                        </div>

                        <div className="footer-contact__text">
                          <span>Puhelin:</span>
                          <strong>
                            <a href={`tel:${resource.phone}`}>
                              {resource.phone}
                            </a>
                          </strong>
                        </div>
                      </div>

                      <div className="footer-contact__item d-flex align-items-center">
                        <div className="footer-contact__icon">
                          <i className="fas fa-envelope-open" />
                        </div>

                        <div className="footer-contact__text">
                          <span>Sähköposti:</span>
                          <strong>
                            <a href={`mailto:${resource.email}`}>
                              {resource.email}
                            </a>
                          </strong>
                        </div>
                      </div>

                      <div className="footer-contact__item d-flex align-items-center">
                        <div className="footer-contact__icon">
                          <i className="fas fa-user-clock" />
                        </div>

                        <div className="footer-contact__text">
                          <span>Aukioloaika:</span>
                          <strong>{data.allSanityHeader.nodes[0].time}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="footer-primary">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 footer-widget">
                        <img
                          src={resource.mainImage.asset.fluid.src}
                          className="logo"
                          alt="logo"
                        />
                        <ul className="social__icons list-unstyled justify-content-start">
                          {resource.app.map((item, index) => {
                            return (
                              <li key={index}>
                                <a href={`${item.link}`}>
                                  <i className={item.icon}></i>
                                </a>
                              </li>
                            )
                          })}
                        </ul>

                        <p className="mt-20">
                          {resource.copyrightText[0].children[0].text}
                        </p>
                      </div>

                      <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <h6 className="footer-widget__title">Nelo Office Oy</h6>
                        <div className="footer-widget__content">
                          <p>{resource.companyDesc[0].children[0].text}</p>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5">
                        <h6 className="footer-widget__title">
                          <FormattedMessage id="contact" />
                        </h6>
                        <div className="footer-widget__content">
                          <p>
                            <strong>
                              <i className="fas fa-map-marker-alt" />
                              <a href={`${resource.addressMap}`}>
                                {resource.address}
                              </a>
                            </strong>
                          </p>
                          <p style={{ width: "200px" }}>
                            <strong>
                              <i className="fas fa-envelope" />
                              <a href={`mailto:${resource.email}`}>
                                {resource.email}
                              </a>
                            </strong>
                          </p>
                          <p>
                            <strong>
                              <i className="fas fa-phone-square" />
                              <a href={`tel:${resource.phone}`}>
                                {resource.phone}
                              </a>
                            </strong>
                          </p>
                          <p>{resource.contacttext}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            )}
          </>
        )
      }}
    />
  )
}
