import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const Language = () => {
  return (
    <>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <ul className="header-actions__list list-unstyled d-flex align-items-center mb-0">
            <li className="dropdown">
              <button
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-globe-asia" />
                <span>{currentLocale == "en" ? "English" : "Finnish"}</span>
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={event => {
                    event.preventDefault()
                    if (currentLocale == "en") {
                      changeLocale("fi")
                    } else {
                      changeLocale("en")
                    }
                  }}
                >
                  {currentLocale == "en" ? "Finnish" : "English"}
                </a>
              </div>
            </li>
          </ul>
        )}
      </IntlContextConsumer>
    </>
  )
}

export default Language
